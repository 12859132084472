export default {
  // 缅甸语
  word1001: 'Burmese',
  word1002: 'အီးမေးလ်',
  word1003: 'စကားဝှက်',
  word1004: 'စကားဝှက်ကို မသိဘူးဆိုရင်?',
  word1005: 'ဝင်ပြောင်း',
  word1006: 'မှတ်ပုံတင်',
  word1007: 'အသုံးပြုသူသဘောတရားချိန်',
  word1008: 'ပုဂ္ဂိုလ်ကိုယ်တိုင်ကျသည့် အခွင့်အရေး',
  word1009: 'ကျွန်ုပ်တို့အကြောင်း',
  word1010: 'အကောင့်က ဖြည့်ထားဖို့မရ',
  word1011: 'စကားဝှက်က ဖြည့်ထားဖို့မရ',
  word1012: 'အကောင့်သည်ဖျက်ထားပြီးဖြစ်သည်',
  word1013: 'အကောင့်သို့မဟုတ်စကားဝှက်မှားယွင်းနေသည်',
  word1014: 'လောဂ်းအောင်မြင်ရသည်',
  word1015: 'နာမည်ခံ',
  word1016: 'ဘယ်ဘက်ထဲက ဂဏန်းကိုထည့်ပါ',
  word1017: 'စာရင်းအသိအမှတ်ကိုရယူပါ',
  word1018: 'စကားဝှက်ထပ်တူပါ',
  word1019: 'ဂဏန်းက အတွင်းမထည့်နိုင်ပါဘူး',
  word1020: 'စာရင်းအသိအမှတ်မှားယွင်းနေသည်',
  word1021: 'နှစ်ဆက်စကားဝှက်မကိုက်ညီပါ',
  word1022: 'နာမည်ခံမှာ အရင်းအလွန်ဖြစ်ရပါမည်',
  word1023: 'အီးမေးလ်ကို အရင်းအလွန်ထည့်သွင်းရန်လိုအပ်သည်',
  word1024: 'စကားဝှက်ကို နောက်ပြီးထပ်တူပြီး အရင်းအလွန်ထည့်သွင်းရန်လိုအပ်သည်',
  word1025: 'စက္ကန့်အနည်းငယ်တွင် ပြန်ကြိုးစားပါ',
  word1026: 'မှတ်ပုံတင်အောင်မြင်စွာပြီးပါပြီ',
  word1027: 'အီးမေးလ် စကားဝှက်သတိပြုချက်',
  word1028: 'အသိအမှတ်ကို အရင်းအလွန်ထည့်သွင်းရန်လိုအပ်သည်',
  word1029: 'စကားဝှက်အသစ်',
  word1030: 'စကားဝှက်ကို ပြောင်းလဲရန်',
  word1031: 'စကားဝှက်အသစ်မှာ အရင်းအလွန်ထည့်သွင်းရန်လိုအပ်သည်',
  word1032: 'ပြောင်းလဲမှုအောင်မြင်စွာပြီးပါပြီ',
  word1033: 'ခြိုင်ပွန်ကိုယ်တိုင်စီးပွားရေးနှင့် ဓာတ်ညွှန်းများအတွက် ဆက်သွယ်ရေးစနစ်',
  word1034: 'ပေါင်းစပ်ဝန်ဆောင်မှုစနစ်',
  word1035: 'သင့်အသုံးပြုသူအကောင့်ကို ဖျက်ထားသည်',
  word1036: 'မှတ်ပုံတင်ပြီး ကုမ္ပဏီအတည်ပြုခြင်း',
  word1037: 'ကုမ္ပဏီအမည်',
  word1038: 'ကုမ္ပဏီလိပ်စာ',
  word1039: 'ကုမ္ပဏီဖုန်းနံပါတ်',
  word1040: 'ထင်ရှားရောက်နေသည့်အရာရှိနေသည်',
  word1041: 'စဥ်းစားပေးထားသည့်လိပ်စာကို တင်ပြသည်',
  word1042: 'JPG/PNG ဖိုင်များကိုပဲ တင်ပြနိုင်သော်လည်း 20MB ထက်နည်းသောဖိုင်များကို တင်ပြသနိုင်သည်',
  word1043: 'ဝန်ကြီးများစာရင်းပေါင်းခွင့်လက်မှတ် တင်ပို့',
  word1044: 'ပေးထားသည်',
  word1045: 'အခုမှ စစ်ဆေးမထား၊ အရင်ဆုံးဖော်ရွေးကြည့်ရန်',
  word1046: 'ဓာတ်ပုံ ရှာဖွေရန်',
  word1047: 'ဦးစီးပွားရေးလက်မှတ်တင်ပြီး တင်ပို့လိုပါသည်',
  word1048: 'ဝန်ကြီးများစာရင်းပေါင်းခွင့်လက်မှတ် (အခွန်စာရင်း) တင်ပို့လိုပါသည်',
  word1049: 'ကုမ္ပဏီအမည်က အလွယ်တကူထည့်သွင်းရမည်',
  word1050: 'ကုမ္ပဏီလိပ်စာက အလွယ်တကူထည့်သွင်းရမည်',
  word1051: 'ကုမ္ပဏီဖုန်းနံပါတ်က စာရင်းအတွင်းထည့်သွင်းခြင်းမရှိဘဲ အဓိပ္ပါယ်မဟုတ်ပါ',
  word1052: 'လက်ရှိအဆင့်အတန်းမှာ စာရင်းအတွင်းထည့်သွင်းခြင်းမရှိဘဲ အဓိပ္ပါယ်မဟုတ်ပါ',
  word1053: 'ဖျက်ရန်အောင်မြင်ရသည်',
  word1054: 'တင်ပြခြင်းအောင်မြင်ရသည်',
  word1055: 'ဝန်ဆောင်မှုအတွက် စာပိုဒ်သည် မှတ်ပုံတင်ထားသောအီးမေးလ်ဖြစ်ပြီး၊ အခြားအီးမေးလ်ကို အသုံးပြုပါ။',
  word1056: 'မှတ်ပုံတင်မှုမရှိပဲ မှတ်ပုံတင်ခြင်း စတင်မည်',
  word1057:
    'ဒီစနစ်သည် နိုင်ငံတကာရောင်းချသည့်စီးပွားရေးနှင့် နိုင်ငံတကာဧပြီး အဓိကထုတ်လုပ်ထားသည်။ စီးပွားရေးအခွင့်အရေးနှင့် ဥပဒေအရင်းအရာများကို ပိုမိုပြုလုပ်ရန်အတွက် ကုမ္ပဏီမှ အတည်ပြုခြင်းကို အကြံပြုပါ။',
  word1058: 'အမှတ်​သာ​ဖြင့်',
  word1059: 'ယခုအချိန်တွင် အတည်ပြုခြင်းမပြုပါ',
  word1060: 'ကုမ္ပဏီအတည်ပြုခြင်း',
  word1061: 'အသုံးပြုသူအီးမေးလ်သည် ရှိနေသော သို့မဟုတ် ဖျက်ထားသော အီးမေးလ်ဖြစ်နိုင်သည်',
  word1062: 'စကားဝှက်ရှည်နှုန်းက 8 မှ 16 ဇာတ်လုံးထားရမည်',
  word1063: 'အီးမေးလ်ပုံစံမှာ မှားနေသည်',
  word1064: 'အီးမေးလ် စစ်သုံးချက်မှတ်တမ်းကို မှားသွားသော သို့မဟုတ် ပို့ပေးခြင်းမရှိသည်',
  word1065: 'အီးမေးလ် စစ်သုံးချက်မှတ်တမ်းမှားနေသည်',

  // 湘企出海系统翻译
  word2001: 'Malaysia Hebei Chamber of Commerce',
  word2002: 'စုစည်းထောင်ပြီးဝန်ဆောင်မှုစနစ်',
  word2003: 'တိုက်ပွဲဝန်ဆောင်မှုနှင့် ဥပဒေဆိုင်ရာ လုံခြုံရေး ဆက်သွယ်ရေးစနစ်',
  word2004: 'ဒီအကောင့်သည် ဖျက်ထားသော်လည်း အသုံးပြုနေပါသည်',

  // 新增
  AccountPlaceholder: 'အီးမေးလ်',
  AccountEmptyTip: 'အီးမေးလ်အကောင့်ဟာ အလွတ်ဖြစ်ရမည်',
  PasswordPlaceholder: 'စကားဝှက်',
  PasswordEmptyTip: 'စကားဝှက်ဟာ အလွတ်ဖြစ်ရမည်',
  AccountPasswordError: 'အကောင့်သို့မဟုတ် စကားဝှက်မှာ မှားနေသည်',
  RightNumber: 'ဘယ်သို့သွားရမည့် ဓာတ်ပုံ၏ဂဏန်းတန်ဖိုးကို ထည့်သွင်းပါ',
  NumberEmptyTip: 'ဓာတ်ပုံထဲရှိ ဂဏန်းတန်ဖိုးက အလွတ်ဖြစ်ရမည်',
  EmailPlaceholder: 'အီးမေးလ်',
  GetEmailCode: 'အီးမေးလ်အတည်ပြုချက်ကို ရယူပါ',
  Retry: ' စက္ကန့် {number} အကြာမှ ထပ်မံကြိုးစားပါ',
  Resend: 'ပြန်ပို့ရန်',
  EmailEmptyTip: 'အီးမေးလ်အကောင့်ဟာ အလွတ်ဖြစ်ရမည်',
  EmailCodePlaceholder: 'အီးမေးလ် စစ်သုံးချက်မှတ်တမ်း',
  EmailCodeEmptyTip: 'အီးမေးလ် စစ်သုံးချက်မှတ်တမ်းဟာ အလွတ်ဖြစ်ရမည်',
  InputNewPassword: 'အသစ်ဝန်ကြီးကို ထည့်သွင်းပါ',
  AgainInputNewPassword: 'ထပ်တိုက် အသစ်ဝန်ကြီးကို ထည့်သွင်းပါ',
  AgainInputPassword: 'ပြန်လည်ဝါကျဝါကျထည့်ပါ။',
  Confirm: 'သဘောတူညီပါ',
  RealName: 'အမှတ်တံဆိပ်အမည်',
  RealNameEmptyTip: 'အမှတ်တံဆိပ်အမည်ဟာ အလွတ်ဖြစ်ရမည်',
  EmailCodeInvalid: 'အီးမေးလ် စစ်သုံးချက်မှတ်တမ်းမှားနေသည်၊ ထပ်မံရယူပါ',
  RegisterCertify: 'မှတ်ပုံတင်ပြီး အတည်ပြုရန်',
  NotCertifyRegister: 'ယခုအချိန်တွင် အတည်ပြုမှုမလုပ်ဆောင်ပါ။ တည်ထောင်ရန်',
  NotCertifyTip:
    'ဒီစနစ်သည် နိုင်ငံခြား ဆက်သွယ်ရေးအတွက် ဓာတ်ပုံဖြင့် အထူးသဖွင့်ပါသည်။ ကုမ္ပဏီအတည်ပြုရန် အကြံပြုခြင်းဖြင့် ပိုမိုစိတ်ကြိုက်သော စီးပွားရေးအခွင့်အရေးနှင့် ဥပဒေအရင်းအရာများကို ခွင့်ပြုရန် အကြံပြုပါသည်။',
  GoCertify: 'အတည်ပြုသွားရန်',
  FileSize:
    'ကျေးဇူးပြုပြီး .jpg သို့မဟုတ် .png ဖော်များဖြင့် ဖိုင်တစ်ခုကို တင်ပြပါ။ ဖိုင်အရွယ်အစားသည် 20MB ထက်နည်းရမည်။',
  AccountInexistence: 'အကောင့်မရှိသော်လည်း စကားဝှက်ကို ပြောင်းလဲရန် မဖြစ်နိုင်ပါ။',
  DeleteTip: 'သင့်အကောင့်ကိုဖျက်ထားသည်။ သင့်စီမံခန့်ခွဲသူကိုဆက်သွယ်ပါ။',
  DearUser: 'အောက်ပါအသုံးပြုသူကျော်၊',
  NotShow: 'မြင်ရမှာမဟုတ်ဘူး',
  PromptContentOne: 'ဟယ်လို။ ဒီစနစ်သည် ',
  PromptContentTwo: ' အသုံးပြုသူများအတွက် အခြားစီးပွားရေးလုပ်ငန်းများ၏ တိုက်အောက် IT စနစ်ကို ဖန်တီးရန်၊ စုစည်းရန် ',
  PromptContentThree:
    ' တိုင်းပြည်နှင့်ပြည်ပ စီးပွားရေးအချက်အလက်များနှင့် ဥပဒေအရင်းအရာများကို စုစည်းပြီး စီးပွားရေးလုပ်ငန်းအသုံးပြုရန် အတွက် သင်ကြားရန်အတွက် သင်၏ ဖော်မှုရှိ တည်နေရာသည် စီးပွားရေးလုပ်ငန်းအသုံးပြုသူများအတွက် အခြားသော အကောင့်များကို ချောင်းထားသော သို့မဟုတ် ဖျက်သိမ်းလိမ့်မည်ဟု သိရှိပါသည်။ သင်ကြားပါ။ ',
  PromptContentFour:
    ' မှတ်ပုံတင်ခင်မှာ သင့်ရဲ့ကိုယ်ရေးအချက်အလက်များကို အမှန်တကယ်ဖြည့်သွင်းပါ။ သတိပြုရန်၊ ကျွန်ုပ်တို့၏ နောက်ခံစီမံခန့်ခွဲမှုစနစ်သည် ဝန်ထမ်းမဟုတ်သော အကောင့်များကို ချိတ်ဆက်သိမ်းသားသို့မဟုတ် ဖျက်ရှားမည်ဟု သတိပြုပါသည်။ ',
  PromptContentFive: 'ကျေးဇူးတင်ပါတယ်။ သင့်ရဲ့စိတ်ကြိုက်ထားမှုအတွက်။ ',
  PromptContentSix: ' နှင့်သင့်ရဲ့အထောက်အကူပြုချက်အတွက် ကျေးဇူးတင်ပါတယ်။ ',
  PromptContentSeven: ' စက်ရုပ်တိုင်း',
  QuickPaymentTip:
    'သင့်ရဲ့စီမံကိန်းကုမ္ပဏီသည် ဝင်ငွေအကျဉ်းချုပ်ဖြင့် သတ်မှတ်ထားပြီး အမြန်ပြီး ကုန်ကျစရိတ်ကို အောင်မြင်ပါ။ မဟုတ်ရင် [အထူးရက်စွဲ] တွင် ဆက်စပ်ရာ ကျန်းမာရေးပေါ် ကြားနာမှုများ ရှိပါသည်။',
  UnableLoginTip: 'ဝင်ရောက်လို့မရပါ',
  Prompt: 'အသိပေးချက်',
  MembershipDues: 'ဝင်ငွေအကျဉ်းချုပ်ကုန်ကျစရိတ်ကို အပေးပါ',
  Determine: 'သတိပေးပါ',
  MembershipExpirationTip: 'သင့်ရဲ့ဝင်ငွေများကုန်ကျစရိတ်သည် ပြီးမြောက်သွားပြီ။ ဒေသခံဖိုင်းကို ပေးပါ။',
  Cancel: 'ပယ်ဖျက်ပါ'
}
