export default {
  // 印尼语
  word1001: 'Bahasa',
  word1002: 'Kotak surat',
  word1003: 'kode',
  word1004: 'Lupa password？',
  word1005: 'Log masuk',
  word1006: 'pendaftaran',
  word1007: 'Perjanjian pengguna',
  word1008: 'Privasi pribadi',
  word1009: 'Tentang kita',
  word1010: 'Nomor rekening tidak bisa kosong',
  word1011: 'Password tidak bisa kosong',
  word1012: 'Nomor rekening telah dibekukan',
  word1013: 'Nomor rekening atau kata sandi yang salah',
  word1014: 'Berhasil masuk',
  word1015: 'Nama panggilan',
  word1016: 'Masukkan nomor kanan',
  word1017: 'Dapatkan captcha',
  word1018: 'ulangi',
  word1019: 'Nomor tidak boleh kosong',
  word1020: 'Angka yang salah',
  word1021: 'Entri tidak cocok. Silakan masukkan ulang',
  word1022: 'Nama panggilan tidak bisa kosong',
  word1023: 'Kotak surat tidak bisa kosong',
  word1024: 'Mengulangi sandi tidak bisa kosong',
  word1025: ' detik setelah coba',
  word1026: 'Pendaftaran berhasil',
  word1027: 'Kode verifikasi email',
  word1028: 'Kode otentikasi tidak bisa kosong',
  word1029: 'Kata sandi baru',
  word1030: 'Ubah sandi',
  word1031: 'Password baru tidak bisa kosong',
  word1032: 'Revisi berhasil',
  word1033: 'Sistem Komunikasi Bisnis dan Hukum Lintas Batas',
  word1034: 'Sistem Layanan Terpadu',
  word1035: 'Akun anda telah dilarang',
  word1036: 'Daftar dan lakukan verifikasi perusahaan',
  word1037: 'Nama perusahaan',
  word1038: 'Alamat perusahaan',
  word1039: 'Nomor telepon perusahaan',
  word1040: 'Jabatan yang dipegang',
  word1041: 'Unggah foto / salinan terpindai Lisensi Bisnis',
  word1042: 'Hanya JPG/PNG, dan ukuran tidak boleh melebihi 20MB.',
  word1043: 'Unggah foto / salinan terpindai Sertifikat Pajak',
  word1044: 'Kirim',
  word1045: 'Lewati verifikasi sekarang, lanjutkan untuk menjelajah.',
  word1046: 'Pratinjau gambar.',
  word1047: 'Unggah foto / salinan terpindai Lisensi Bisnis',
  word1048: 'Unggah foto / salinan terpindai Sertifikat Pajak',
  word1049: 'Silakan masukkan nama perusahaan.',
  word1050: 'Silakan masukkan alamat perusahaan.',
  word1051: 'Nomor telepon perusahaan tidak boleh kosong',
  word1052: 'Silakan masukkan pekerjaan Anda.',
  word1053: 'Berhasil dihapus.',
  word1054: 'Berhasil dikirim.',
  word1055: 'Maaf, email ini sudah terdaftar. Silakan gunakan email lain.',
  word1056: 'Daftar dan lewati verifikasi perusahaan',
  word1057:
    'Sistem ini dirancang khusus untuk konektivitas lintas batas, disesuaikan untuk bisnis guna memberikan lebih banyak peluang komersial dan sumber daya hukum. Kami sarankan Anda untuk melakukan verifikasi perusahaan untuk mendapatkan manfaat tambahan',
  word1058: 'Pemberitahuan',
  word1059: 'Lewati verifikasi',
  word1060: 'Verifikasi perusahaan',
  word1061: 'Email pengguna sudah ada atau sudah dihapus',
  word1062: 'Panjang kata sandi harus antara 8 hingga 16 karakter',
  word1063: 'Itu format email yang salah',
  word1064: 'Kode verifikasi email kadaluarsa atau tidak dikirim',
  word1065: 'Kode verifikasi email salah',

  // 湘企出海系统翻译
  word2001: 'Malaysia Hebei Chamber of Commerce',
  word2002: 'Sistem Layanan Terpadu',
  word2003: 'Sistem Komunikasi Bisnis dan Hukum Lintas Batas',
  word2004: 'Akun itu telah dinonaktifkan',

  // 新增
  AccountPlaceholder: 'Name Akun',
  AccountEmptyTip: 'Silakan masukkan nama akun',
  PasswordPlaceholder: 'Kata Sandi',
  PasswordEmptyTip: 'Silakan masukkan kata sandi',
  AccountPasswordError: 'Nama akun atau kata sandi salah',
  RightNumber: 'Silakan masukkan angka di sebelah kanan',
  NumberEmptyTip: 'Silakan masukkan angka di sebelah kanan',
  EmailPlaceholder: 'Email',
  GetEmailCode: 'Dapatkan Kode Verifikasi',
  Retry: ' detik untuk mengirim ulang',
  Resend: 'Kirim ulang',
  EmailEmptyTip: 'Silakan masukkan alamat Email',
  EmailCodePlaceholder: 'Silakan masukkan kode verifikasi Email',
  EmailCodeEmptyTip: 'Kode verifikasi Email tidak boleh kosong',
  InputNewPassword: 'Silakan masukkan kata sandi baru',
  AgainInputNewPassword: 'Silakan masukkan ulang kata sandi baru Anda',
  AgainInputPassword: 'Silakan masukkan ulang kata sandi Anda',
  Confirm: 'Konfirmasi',
  RealName: 'Nama Asli',
  RealNameEmptyTip: 'Silakan masukkan nama asli Anda',
  EmailCodeInvalid: 'Kode verifikasi email tidak valid, silakan dapatkan lagi',
  RegisterCertify: 'Daftar dan Verifikasi',
  NotCertifyRegister: 'Daftar Langsung Tanpa Verifikasi',
  NotCertifyTip:
    'Sistem ini ditujukan untuk interkoneksi lintas batas, dan kami menyarankan Anda untuk memverifikasi perusahaan Anda untuk menikmati lebih banyak peluang bisnis dan sumber daya hukum',
  GoCertify: 'Pergi untuk Memverifikasi',
  FileSize: 'Hanya JPG/PNG, dan ukuran tidak boleh melebihi 20MB',
  AccountInexistence: 'Akun tidak ada, dan kata sandi tidak dapat diubah.',
  DeleteTip: 'Akun Anda telah dihapus. Silakan hubungi administrator Kamar Dagang Anda.',
  DearUser: 'Pengguna yang Terhormat,',
  NotShow: 'Tidak lagi ditampilkan',
  PromptContentOne: 'Halo, sistem ini adalah sistem IT internal yang diciptakan oleh ',
  PromptContentTwo:
    ' untuk anggota perusahaan. ia menyempurnakan informasi komersial dan sumber daya hukum dari sumber rumah dan internasional di ',
  PromptContentThree:
    ' untuk penggunaan perusahaan. ia cocok untuk pemegang saham, manajer umum, kepala departemen pemasaran, kepala departemen penjualan, kepala departemen pembelian, dan kepala departemen hukum anggota perusahaan. Silakan konfirmasi bahwa organisasi Anda adalah anggota dari ',
  PromptContentFour:
    ' dan benar-benar mengisi informasi pribadi Anda sebelum mendaftar. Sistem manajemen backend kami akan membeku atau menghapus akun bukan anggota. Silakan diberitahu.',
  PromptContentFive: 'Sementara itu terima kasih telah mengikuti ',
  PromptContentSix: ', dan terima kasih atas dukungan anda juga!',
  PromptContentSeven: ' sekretariat',
  QuickPaymentTip:
    'Kuota biaya anggota kamar dagang Anda telah ditetapkan. Mohon selesaikan pembayaran dengan cepat, atau Anda akan menghadapi konsekuensi pada [tanggal spesifik]',
  UnableLoginTip: 'Tidak dapat masuk',
  Prompt: 'Pemberitahuan',
  MembershipDues: 'Bayar biaya keanggotaan',
  Determine: 'Konfirmasi',
  MembershipExpirationTip: 'Keanggotaan Anda telah berakhir. Mohon perbaharui dengan membayar biaya keanggotaan',
  Cancel: 'Batalkan',
  ImgOnly: 'Format gambar hanya bisa jpeg、jpg、png、bmp、tiff',
  PictureSize2MBTip: 'Ukuran gambar tidak boleh melebihi 2MB',
  UploadSuccess: 'Berhasil diunggah'
}
