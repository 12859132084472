export default {
  // 中文
  word1001: 'Chinese',
  word1002: '邮箱',
  word1003: '密码',
  word1004: '忘记密码？',
  word1005: '登录',
  word1006: '注册',
  word1007: '用户协议',
  word1008: '个人隐私',
  word1009: '关于我们',
  word1010: '账号不能为空',
  word1011: '密码不能为空',
  word1012: '该账号已被冻结',
  word1013: '账号或密码错误',
  word1014: '登录成功',
  word1015: '昵称',
  word1016: '输入右方的数字',
  word1017: '获取验证码',
  word1018: '重复',
  word1019: '数字不能为空',
  word1020: '验证码不正确',
  word1021: '两次密码不一致',
  word1022: '昵称不能为空',
  word1023: '邮箱不能为空',
  word1024: '重复密码不能为空',
  word1025: '秒后重试',
  word1026: '注册成功',
  word1027: '邮箱验证码',
  word1028: '验证码不能为空',
  word1029: '新密码',
  word1030: '修改密码',
  word1031: '新密码不能为空',
  word1032: '修改成功',
  word1033: '跨境商务&法务互通系统',
  word1034: '综合服务系统',
  word1035: '你的账号已被禁用',
  word1036: '注册并进行公司认证',
  word1037: '公司名称',
  word1038: '公司地址',
  word1039: '公司电话',
  word1040: '在任职位',
  word1041: '上传营业执照',
  word1042: '只能上传jpg/png文件，且不超过20MB',
  word1043: '上传税务证',
  word1044: '提交',
  word1045: '暂不验证，先行浏览',
  word1046: '图片预览',
  word1047: '请上传营业执照',
  word1048: '请上传税务证',
  word1049: '公司名称不能为空',
  word1050: '公司地址不能为空',
  word1051: '公司电话不能为空',
  word1052: '在任职位不能为空',
  word1053: '删除成功',
  word1054: '提交成功',
  word1055: '抱歉，该邮箱已经被注册，请使用其他邮箱',
  word1056: '注册暂不进行公司认证',
  word1057: '本系统专用于跨境互通，为企业量身定制，以提供更多的商业机会和法律资源。建议您进行公司认证获得更多权益',
  word1058: '提示',
  word1059: '暂不认证',
  word1060: '公司认证',
  word1061: '用户邮箱已存在或已被删除',
  word1062: '密码长度在 8 到 16 个字符',
  word1063: '邮箱格式有误',
  word1064: '邮箱验证码过期或未发送',
  word1065: '邮箱验证码错误',

  // 湘企出海系统翻译
  word2001: '马来西亚河北总商会',
  word2002: '综合服务系统',
  word2003: '跨境商务&法务互通系统',
  word2004: '该账号已被禁用',

  // 新增
  AccountPlaceholder: '邮箱',
  AccountEmptyTip: '邮箱账号不能为空',
  PasswordPlaceholder: '密码',
  PasswordEmptyTip: '密码不能为空',
  AccountPasswordError: '账号或密码错误',
  RightNumber: '请输入右侧图片中的数字',
  NumberEmptyTip: '图片中数字不能为空',
  EmailPlaceholder: '邮箱',
  GetEmailCode: '获取邮箱验证码',
  Retry: ' 秒后重试',
  Resend: '重新发送',
  EmailEmptyTip: '邮箱账号不能为空',
  EmailCodePlaceholder: '邮箱验证码',
  EmailCodeEmptyTip: '邮箱验证码不能为空',
  InputNewPassword: '请输入新密码',
  AgainInputNewPassword: '请再次输入新密码',
  AgainInputPassword: '请再次输入密码',
  Confirm: '确定',
  RealName: '真实姓名',
  RealNameEmptyTip: '真实姓名不能为空',
  EmailCodeInvalid: '邮箱验证码无效，请重新获取',
  RegisterCertify: '注册并进行认证',
  NotCertifyRegister: '暂不认证，直接注册',
  NotCertifyTip: '本系统专用于跨境互通，建议您进行企业认证以享受更多商业机会和法律资源',
  GoCertify: '前往认证',
  FileSize: '请上传.jpg或.png格式的文件，且文件大小不应超过20MB',
  AccountInexistence: '该账号不存在，无法修改密码。',
  DeleteTip: '您的账号已被删除，请联系您商会管理员。',
  DearUser: '尊敬的用户，',
  NotShow: '不再显示',
  PromptContentOne: '您好，本系统是',
  PromptContentTwo: '为企业会员打造的内部IT系统，聚合',
  PromptContentThree:
    '境内外的商业信息和法律资源为企业所用，适合企业会员的股东、总经理、市场部负责人、销售部负责人、采购部负责人、法务部负责人使用。请您在注册之前先确认您单位属于',
  PromptContentFour: '的会员，且如实填写您个人信息。我们后台管理系统将冻结或删除非会员账号，请您知悉。',
  PromptContentFive: '同时感谢您关注',
  PromptContentSix: '，也感谢您的支持！',
  PromptContentSeven: '秘书处',
  QuickPaymentTip: '您的商会已设置缴纳会费，请尽快缴纳，否则您将会在',
  UnableLoginTip: '无法登录',
  Prompt: '提示',
  MembershipDues: '缴纳会费',
  Determine: '确定',
  MembershipExpirationTip: '您的会员已过期，请缴纳会员费',
  Cancel: '取消',
  ImgOnly: '图片格式只能为 jpeg、jpg、png、bmp、tiff',
  PictureSize2MBTip: '上传图片大小不能超过2MB',
  UploadSuccess: '上传成功'
}
